import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueSession from 'vue-session'
import { store } from '@/store/index.js'
import { language } from '@/resources/lang/kr/common'
import VueExcelXlsx from "vue-excel-xlsx"
import Clipboard from 'v-clipboard'

Vue.config.productionTip = false
var sessionOptions = {
	persist: true
}
Vue.use(VueSession, sessionOptions)
Vue.use(VueExcelXlsx)
Vue.prototype.$language = language
 
Vue.use(Clipboard)

new Vue({
  router,
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app')
