<template>
	<div class="side"
		v-if="!program.not_header"
	>
		<div class="nav-list">
			<template
				v-for="(item, index) in item_list">
				<div class="nav-item"
					v-if="item.use && item.grade.indexOf(Number(user.basic.level)) > -1"
					:key="index"
				>
					<div class="title">{{ item.title }}</div>
					<template
						v-for="(item2, index2) in item.list"
					>
						<div class="menu"
							v-if="item2.use && item2.grade.indexOf(Number(user.basic.level)) > -1"
							:key="index2"
							:class=" { active: item2.to == $route.name || item2.include == $route.name }"
						>
							<a
								v-if="item2.ready"
								@click="none"
							>{{ item2.name }}</a>
							<router-link 
								v-if="item2.use && !item2.ready"
								:to="{ name: item2.to}"
							>{{ item2.name }}</router-link>
						</div>
					</template>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Side'
		,props: ['user', 'program']
		,data: function(){
			return {
				items: this.$language.menu
			}
		}
		,computed: {
			item_list: function(){
				let self = this
				
				return this.items.filter(function(item){
					if(item.grade.indexOf(Number(self.user.basic.level)) >= -1){
						return item
					}
				})
			}
		}
		,methods: {
			none: function(){
				alert('준비중입니다')
			}
		}
		,created: function(){
		}
	}
</script>

