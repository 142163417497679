<template>
	<div id="app">
		<Layout 
			:config="config"
			:codes="codes"
			
			@setAuth="setAuth"
		/>
	</div>
	
</template>	

<script>

import Layout from '@/view/Layout/Layout.vue'

import { Axios } from '@/resources/axios/axios'
import { codes } from '@/resources/config/codes'

export default {
	name: 'App'
	,components: { Layout }
	,metaInfo: {
		title: codes.metaInfo.title
	}
	,data: function(){
		return {
			config: {
				TOKEN: "TOKEN"
				,callBack: {
				}
				,program: {
				}
				,user: {
					basic: {
					}
					,settlement: {
					}
					,company: {
					}
					,paymentItems: ['', '', '']
				}
			}
			,codes: codes
		}
	}
	,methods: {
		isToken: async function(){
			if(!sessionStorage.getItem('TOKEN')){
				console.log('not isToken')
				this.$router.push({ name: 'AuthLogin' })
			}else{
				await this.setAuth(sessionStorage.getItem('TOKEN'))
			}
		}
		,setCodes: async function(TOKEN){
			try{
				const result = await Axios({
					method: 'get'
					,url: '/merchants/apply'
					,authorize: true
					,TOKEN: TOKEN
				})
				
				if(result.success){
					Object.assign(this.codes, result.data.content)
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message })
				}
			}catch(E){
				console.log(E)
				this.$emit('setNotify', { type: 'error', message: E })
			}
		}
		,setAuth: async function(TOKEN){
			try{
				const result = await Axios({
					method: 'get'
					,url: '/users/me'
					,authorize: true
					,TOKEN: TOKEN
				})
				
				if(result.success){
				
					if(result.data.content.role == 'ROLE_MERCHANT'){
						this.getMerchant(TOKEN)
					}else{
						this.$set(this.config.user.basic, 'level', 2)
						this.$set(this.config.user.basic, 'userId', result.data.content.businessName)
						this.$set(this.config.user.settlement, 'settlementCycleName', result.data.content.settlementCycleName)
						this.$router.push({ name: 'PaymentsHistory' })
					}
					
					//await this.setCodes(sessionStorage.getItem('TOKEN'))
				}else{
					if(this.$route.path.toLowerCase() != '/login'){
						
						this.$router.push('/login')
						//this.$router.push('/')
						this.$emit('setNotify', {type: 'error', message: result.message })
					}
				}
			}catch(E){
				console.log(E)
				this.$router.push('/login')
				this.$emit('setNotify', {type: 'error', message: E})
			}
		}
		,getMerchant: async function(TOKEN){
			try{
				const result = await Axios({
					method: 'get'
					,url: '/me'
					,authorize: true
					,TOKEN: TOKEN
				})
				
				if(result.success){
					this.config.user = result.data.content
					this.$set(this.config.user.basic, 'level', 1)
					
					//await this.setCodes(sessionStorage.getItem('TOKEN'))
				}else{
					if(this.$route.path.toLowerCase() != '/login'){
						
						this.$router.push('/login')
						//this.$router.push('/')
					}
				}
			}catch(E){
				console.log(E)
				this.$router.push('/login')
				this.$emit('setNotify', {type: 'error', message: E})
			}
		}
	}
	,created: function(){
		this.isToken()
	}
}
</script>


<style lang="css" src="@/assets/css/reset.css"></style>
<style lang="css" src="@/assets/css/style.css"></style>
<style lang="css" src="@/assets/css/custom.css"></style>

<!-- xeicon -->
<style>
	@import "//cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css"
</style>