

export const codes = {
	metaInfo: {
		title: 'DOUBLES'
		,copyright: 'doubles'
	}
	,posStatus: [
		{ code: '', codeName: '단말기 상태를 선택하세요' }
		,{ code: 'Normal', codeName: '정상사용' }
		,{ code: 'Pause', codeName: '사용중지' }
		,{ code: 'Termination', codeName: '해지' }
	]
	,posType: [
		{ code: '', codeName: '단말기 타입을 선택하세요' }
		,{ code: 'Fixed', codeName: '유선 단말기' }
		,{ code: 'Wireless', codeName: '무선 단말기' }
	]
	,settlementStatus: [
		{ code: '', codeName: '전체'}
		,{ code: 'Pause', codeName: '정산대기'}
		,{ code: 'Normal', codeName: '정산완료'}
		,{ code: '003', codeName: '지급보류'}
	]
	,merchants: {
		status: [
			{ code: '', codeName: '전체'}
			,{ code: 'Normal', codeName: '정상사용'}
			,{ code: 'Pause', codeName: '사용중지'}
			,{ code: 'Termination', codeName: '해지'}
		]
	}
	,payments: {
		status: [
			{ code: '', codeName: '전체'}
			,{ code: 'Normal', codeName: '결제 요청'}
			,{ code: 'Cancel', codeName: '결제 취소'}
			,{ code: 'Fail', codeName: '결제 실패'}
			,{ code: 'Expiry', codeName: '결제 만료'}
		]
		,smsType: [
			{ code: '', codeName: '전체'}
			,{ code: 'Normal', codeName: '건별 결제'}
			,{ code: 'Multiple', codeName: '대량 결제'}
		]
		,cardType: [
			{ code: '', codeName: '전체'}
			,{ code: '001', codeName: '삼성카드'}
			,{ code: '002', codeName: '현대카드'}
		]
		,installment: [
			{ code: '', codeName: '전체'}
			,{ code: '001', codeName: '일시불'}
			,{ code: '002', codeName: '할부'}
		]
	}
	,serviceType: [
		{ code: '001', codeName: '인증(온라인)'}
		,{ code: '002', codeName: '비인증(수기)'}
		,{ code: '003', codeName: 'OFF PG(단말기)'}
	]
	,installment: [
		{ code: '00', codeName: '일시불'}
		,{ code: '02', codeName: '2개월'}
		,{ code: '03', codeName: '3개월'}
		,{ code: '04', codeName: '4개월'}
		,{ code: '05', codeName: '5개월'}
		,{ code: '06', codeName: '6개월'}
	]
	,transStats: [
		{ code: '00', codeName: '승인'}
		,{ code: '05', codeName: '취소 요청'}
		,{ code: '10', codeName: '당일 취소'}
		,{ code: '20', codeName: '매입 취소'}
		,{ code: '90', codeName: '망상 취소'}
	]
}